<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>
    <div class="table-header">
      Cantidad de transformaciones: <b>{{ articleTransformations.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="articleTransformations"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="articleTransformations"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.DESCRIPCION}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="DESCRIPCION"
      >
        <template #empty>
          <div class="has-text-centered">
            No hay trasnformaciones de material
          </div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
          >
            <p v-if="!checkIfValid(props.row[column.field])">
              {{ "Sin información" }}
            </p>
            <p>{{ props.row[column.field] }}</p>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddArticleTransformation
                  :transformationInformation="props.row"
                />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import AddArticleTransformation from "./AddArticleTransformation.vue";

export default {
  name: "ArticleTransformationsDetailsList",
  components: { AddArticleTransformation },
  data() {
    //Proveedores
    return {
      perPage: 50,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "DESCRIPCION",
            label: "Descripción",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "STATUS_FOR",
            label: "Estatus",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "formatedDate",
            label: "Fecha",
            sortable: true,
            searchable: true,
            display: true,
            customSort: this.sortByDate,
          },
          {
            field: "ORIGEN",
            label: "Artículo origen",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "ORIGEN_SAT",
            label: "Clave SAT origen",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "CANTIDAD_ORIGEN",
            label: "Cantidad origen",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "DESTINO",
            label: "Artículo destino",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "DESTINO_SAT",
            label: "Clave SAT destino",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "CANTIDAD_DESTINO",
            label: "Cantidad destino",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "CLAVE_ALM_FOR",
            label: "Almacen",
            sortable: true,
            searchable: true,
            display: true,
          },
        ],
        "article-transformation-details-list-columns"
      ),
      refreshLoading: false,
    };
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETARTICLESTRANSFORMATION"));
      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETFORMULAS"));
      allPromises.push(this.$store.dispatch("GETARTICLES"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    openAddArticleTransformation() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddArticleTransformation,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "article-transformation-details-list-columns"
        );
      },
      deep: true,
    },
  },
  computed: {
    articleTransformations() {
      let transformationDetails = [];

      for (const singleTransformation of this.$store.getters
        .ARTICLETRANSFORMATIONS) {
        for (const singleMaterial of singleTransformation.MATERIALES) {
          const transformationWithProps = { ...singleTransformation };

          transformationWithProps.ORIGEN = singleMaterial.CLAVE_ART.DESCRIPCION;
          transformationWithProps.ORIGEN_SAT =
            singleMaterial.CLAVE_ART.CLAVE_SAT.CVE_PROSER;
          transformationWithProps.CANTIDAD_DESTINO = singleMaterial.CANTIDAD;

          transformationWithProps.formatedDate = moment(
            singleTransformation.FECHA
          ).format("DD-MM-YYYY HH:mm");

          transformationWithProps.STATUS_FOR = "Activo";

          if (singleTransformation.STATUS === "AC") {
            transformationWithProps.STATUS_FOR = "Activo";
          }

          if (singleTransformation.STATUS === "CA") {
            transformationWithProps.STATUS_FOR = "Cancelado";
          }

          transformationWithProps.CLAVE_ALM_FOR =
            singleTransformation.CLAVE_ALM.NOMBRE_ALM;

          if (singleTransformation.RESULTADOS) {
            for (const singleResult of singleTransformation.RESULTADOS) {
              const resultWithProps = { ...singleResult };

              transformationWithProps.DESTINO =
                resultWithProps.CLAVE_ART.DESCRIPCION;
              transformationWithProps.DESTINO_SAT =
                resultWithProps.CLAVE_ART.CLAVE_SAT.CVE_PROSER;
              transformationWithProps.CANTIDAD_DESTINO =
                resultWithProps.CANTIDAD;
            }

            transformationDetails.push(transformationWithProps);
          }
        }
      }

      return transformationDetails;
    },
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        articleWithProps.activeDimensionsWeight = true;
        articleWithProps.disableIva = true;
        articleWithProps.activeFamily = true;
        articleWithProps.activeSFamily = true;
        articleWithProps.activeSSFamily = true;
        articleWithProps.activeSSSFamily = true;
        articleWithProps.shippingActive = true;
        articleWithProps.tarrifActive = true;
        articleWithProps.retentionActive = true;
        articleWithProps.totalQuantity = 0;
        if (singleArticle.FE_ULTVTA) {
          articleWithProps.lastSaleFormat = moment(
            singleArticle.FE_ULTVTA
          ).format("DD-MM-YYYY HH:mm");
        }
        if (singleArticle.FE_ULTCMP) {
          articleWithProps.lastBuyFormat = moment(
            singleArticle.FE_ULTCMP
          ).format("DD-MM-YYYY HH:mm");
        }
        if (singleArticle.CANT_ALMACENES) {
          for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
            articleWithProps.totalQuantity += Number(
              singleArticleWarehouse.CANTIDAD
            );
          }
        }
        return articleWithProps;
      });
    },
  },
  async created() {
    moment.locale("es");

    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETARTICLESTRANSFORMATION"));
    allPromises.push(this.$store.dispatch("GETFORMULAS"));

    await Promise.all(allPromises);
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
